export default class StatService {
  constructor ({ $axios, store, router }) {
    this.$axios = $axios
    this.store = store
    this.router = router
    this.metrics = [
      {
        name: 'sms_messages_sent',
        label: 'Sent Messages',
        yAxisId: 'count',
        callback: element => element?.sum_count,
        format: value => value ? Intl.NumberFormat().format(value) : 0
      },
      {
        name: 'sms_messages_received',
        label: 'Received Messages',
        yAxisId: 'count',
        callback: element => element?.sum_count,
        format: value => value ? Intl.NumberFormat().format(value) : 0
      },
      {
        name: 'sms_messages_response_rate',
        label: 'Response Rate',
        yAxis: 'percentage',
        callback: element => Math.round((element?.response_rate || 0) * 100),
        format: value => `${value}%`,
        aggregators: 'latest'
      },
      {
        name: 'sms_contacts',
        label: 'Unique Contacts',
        callback: element => element?.sum_count,
        format: value => value ? Intl.NumberFormat().format(value) : 0,
        aggregators: 'unique_contacts'
      }
    ]
  }

  getStats (params) {
    return Promise.all(this.metrics.map(async (metric) => {
      params.start.toISOString()
      params.end.toISOString()
      const statData = await this.$axios.get('/api/stats', {
        params: {
          metric: metric.name,
          aggregators: metric.aggregators,
          ...params
        }
      })
      statData.data.metricProps = metric
      return statData.data
    }))
  }
}
